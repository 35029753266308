document.addEventListener('DOMContentLoaded', function () {
    var video = document.getElementById('home-page_top_video');
    var playPauseBtn = document.getElementById('home-page_top_video_button');
    var icon = playPauseBtn.querySelector('i');

    playPauseBtn.addEventListener('click', function () {
        if (video.paused) {
            video.play();
            icon.classList.remove('fa-play');
            icon.classList.add('fa-pause');
            playPauseBtn.setAttribute('aria-label', 'Pause dekorativ video');
        } else {
            video.pause();
            icon.classList.remove('fa-pause');
            icon.classList.add('fa-play');
            playPauseBtn.setAttribute('aria-label', 'Spill av dekorativ video');
        }
    });
});
